import { Nav, Navbar, NavDropdown } from 'react-bootstrap'
import Link from 'next/link'
// import Icon from '@/components/elements/Icon'
import cmsContactBlockSettingsObject from '@/entities/cmsContactBlockSettings/interface'
import ContactModal from '@/components/template/navigations/MainNavigation/ContactModal'

interface Props {
  categories: any
  contactBlockSettings: cmsContactBlockSettingsObject
}

const MainNavigation = ({ categories, contactBlockSettings }: Props) => {
  return (
    <>
      <Navbar.Toggle aria-controls="main-nav" />
      <Navbar.Collapse id="main-nav">
        <Nav className="me-auto">&nbsp;</Nav>
        <Nav className="main-nav">
          {categories &&
            categories.map((category: any, index: number) => {
              return (
                <NavDropdown title={category.name} id={`collapsible-nav-dropdown-${index}`} key={index}>
                  {category.pages
                    // filter out pages that are not to be displayed (OFFLINE/HIDDEN)
                    .filter((page: any) => {
                      return page.onlineStatus === 'ONLINE'
                    })
                    .map((page: any, index: number) => {
                      return (
                        <Link href={`/${category.seoSettings.urlSlug}/${page.seoSettings.urlSlug}`} passHref key={index}>
                          <NavDropdown.Item>
                            <div>{page.name}</div>
                          </NavDropdown.Item>
                        </Link>
                      )
                    })}
                </NavDropdown>
              )
            })}
          <div className="icon-links">
            {/*<button className="btn-unstyled icon-link" aria-label="Merkliste">*/}
            {/*  <Icon name="like" className="icon" />*/}
            {/*</button>*/}
            <ContactModal contactBlockSettings={contactBlockSettings} />
          </div>
        </Nav>
      </Navbar.Collapse>
    </>
  )
}

export default MainNavigation
